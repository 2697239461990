export default {
  SET_CANDIDATE(state, data) {
    state.candidate = data;
  },
  SET_POSITIONS(state, data) {
    if (state.candidate) state.candidate.positions = data;
  },
  SET_ALL_CANDIDATES: (state, data) => {
    state.allCandidates = data;
  },
  SET_ALL_POSITIONS: (state, data) => {
    state.allPositions = data;
  },
  SET_AUTO_SEND_CAN_STATEMENTS: (state, data) => {
    if (state.candidate)
      state.candidate.autoSendNonCandidateStatementsToAdmin = data;
  }
};
