<template>
  <b-modal
    v-model="show"
    id="modal-theme-preview"
    :title="title"
    size="lg"
    scrollable
    @hidden="$emit(`hidden`)"
    @ok="$emit(`ok`, theme)"
    ok-title="Select"
    cancel-title="Close"
  >
    <b-img
      v-if="theme.src"
      :src="require(`@/assets/images/admin/${theme.src}`)"
      :alt="theme.alt"
      fluid
    ></b-img>
  </b-modal>
</template>

<script>
export default {
  props: {
    showThemePreview: {
      type: Boolean,
      require: true,
      default: () => {
        return false;
      },
    },
    theme: {
      type: Object,
      require: false,
      default: () => {
        return {
          id: 0,
          src: "t1-black.png",
          alt: "Template Preview",
        };
      },
    },
  },
  computed: {
    title() {
      const color = this.colors.filter((x) => x.id == this.theme.colorId)[0];
      return `${this.theme.alt} (${color?.text})`;
    },
  },
  watch: {
    showThemePreview() {
      this.show = this.showThemePreview;
    },
  },
  data() {
    return {
      show: false,
      colors: [
        {
          id: 1,
          text: "Black/Red",
        },
        {
          id: 2,
          text: "Blue",
        },
        {
          id: 3,
          text: "Salmon",
        },
        {
          id: 4,
          text: "Teal",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
