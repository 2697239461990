import Repository from "@/repositories/RepositoryFactory";
import { ExtractTiktok } from "@/services/helpers";

const CandidateRepository = Repository.get("CandidateRepository");

export const GetUserAsync = async userId => {
  try {
    const response = await CandidateRepository.GetUser(userId);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const GetCandidateByNameAsync = async name => {
  try {
    const response = await CandidateRepository.GetCandidateByName(name);

    let extras = [];
    let videos = response.data.data.medias.filter(function(video) {
      return video.mediaType == 2;
    });

    for (let index in videos) {
      let video = videos[index];

      if (video.path.includes("tiktok")) {
        const result = await ExtractTiktok(video.path);
        extras.push({
          id: video.id,
          detail: result
        });
      } else if (video.path.includes("instagram")) {
        extras.push({
          id: video.id,
          detail: null
        });
      }
    }

    response.data.data.medias.map(media => {
      if (media.mediaType == 2) {
        if (media.path.includes("tiktok")) {
          if (media.id) {
            media.extras = {
              type: "tiktok",
              detail: extras.filter(x => x.id == media.id)[0].detail
            };
          }
        } else if (media.path.includes("instagram")) {
          if (media.id) {
            media.extras = {
              type: "instagram",
              detail: extras.filter(x => x.id == media.id)[0].detail
            };
          }
        }
      }
    });

    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const GetPositionsAsync = async () => {
  try {
    const response = await CandidateRepository.GetPositions();
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveThemeAsync = async data => {
  try {
    const response = await CandidateRepository.SaveTheme(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEmailAsync = async data => {
  try {
    const response = await CandidateRepository.SaveEmail(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveSocialLinksAsync = async data => {
  try {
    const response = await CandidateRepository.SaveSocialLinks(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveNamePositionAsync = async data => {
  let formData = new FormData();

  formData.append("userId", data.userId);
  formData.append("name", data.name);
  formData.append("position", data.position);

  if (data.headshot) formData.append("headshot", data.headshot);

  if (data.headshotCurrentUrl)
    formData.append("headshotCurrentUrl", data.headshotCurrentUrl);

  if (data.homePageHeadshot) formData.append("homePageHeadshot", data.homePageHeadshot);

  if (data.homePageHeadshotCurrentUrl)
    formData.append("homePageHeadshotCurrentUrl", data.homePageHeadshotCurrentUrl);

  try {
    const response = await CandidateRepository.SaveNamePosition(formData);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveIntroTextAsync = async data => {
  try {
    const response = await CandidateRepository.SaveIntroText(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveOpenContentAsync = async data => {
  try {
    const response = await CandidateRepository.SaveOpenContent(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveFeatureTextAsync = async data => {
  let formData = new FormData();

  formData.append("userId", data.userId);
  formData.append("title", data.title);
  formData.append("text", data.text);

  if (data.featureImage) formData.append("featureImage", data.featureImage);

  if (data.featureImageUrl)
    formData.append("featureImageUrl", data.featureImageUrl);

  try {
    const response = await CandidateRepository.SaveFeatureText(formData);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveMediaAsync = async data => {
  let formData = new FormData();
  let index = 0;

  formData.append("userId", data.userId);

  data.photos.forEach(e => {
    if (e.file) {
      formData.append(`photos[${index}].File`, e.file, e.fileName);
      formData.append(`photos[${index}].Caption`, e.caption);
    }
    index++;
  });

  index = 0;

  data.videos.forEach(e => {
    if (e.url) {
      formData.append(`videos[${index}].Url`, e.url);
      formData.append(`videos[${index}].Caption`, e.caption);
    }
    index++;
  });

  try {
    const response = await CandidateRepository.SaveMedia(formData);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveMediaTableAsync = async data => {
  try {
    const response = await CandidateRepository.SaveMediaTable(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEventAsync = async data => {
  let formData = new FormData();

  formData.append("userId", data.userId);
  formData.append("title", data.title);
  formData.append("date", data.date);
  formData.append("time", data.time);
  formData.append("description", data.description);
  formData.append("ctaText", data.ctaText);
  formData.append("ctaLink", data.ctaLink);
  formData.append("status", data.status);
  formData.append("isVisible", data.visible);

  if (data.id) formData.append("id", data.id);
  if (data.existingPhotoUrl)
    formData.append("coverPhotoUrl", data.existingPhotoUrl);

  if (data.coverPhoto) formData.append("coverPhoto", data.coverPhoto);

  try {
    const response = await CandidateRepository.SaveEvent(formData);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEventTableAsync = async data => {
  try {
    const response = await CandidateRepository.SaveEventTable(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEndorsementTextAsync = async data => {
  try {
    const response = await CandidateRepository.SaveEndorsementText(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEndorsementTableAsync = async data => {
  try {
    const response = await CandidateRepository.SaveEndorsementTable(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SubmitEndorsementAsync = async data => {
  try {
    const response = await CandidateRepository.SubmitEndorsement(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SendFailedEndorsementEmailAsync = async data => {
  try {
    const response = await CandidateRepository.SendFailedEndorsementEmail(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const HasEndorsedAsync = async data => {
  try {
    const response = await CandidateRepository.HasEndorsed(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SubmitPetitionAsync = async data => {
  try {
    const response = await CandidateRepository.SubmitPetition(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SubmitEmailAsync = async data => {
  try {
    const response = await CandidateRepository.SubmitEmail(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SubmitHelpMailAsync = async data => {
  try {
    const response = await CandidateRepository.SubmitHelpMail(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEndorsementGroupAsync = async data => {
  try {
    const response = await CandidateRepository.SaveEndorsementGroup(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const UpdateEndorsementGroupAsync = async data => {
  try {
    const response = await CandidateRepository.UpdateEndorsementGroup(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const DeleteEndorsementGroupAsync = async data => {
  try {
    const response = await CandidateRepository.DeleteEndorsementGroup(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const UpdateLayoutPreferenceAsync = async data => {
  try {
    const response = await CandidateRepository.UpdateLayoutPreference(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const UpdateAutoSendNonCandidateStatements = async data => {
  try {
    const response = await CandidateRepository.UpdateAutoSendNonCandidateStatements(
      data
    );
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const RemoveFeaturedEndorsementGroupItem = async data => {
  try {
    const response = await CandidateRepository.RemoveFeaturedEndorsementGroupItem(
      data
    );
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SaveEndorsementRowAsync = async data => {
  let formData = new FormData();
  formData.append("candidateId", data.candidateId);
  formData.append("endorsementId", data.endorsementId);
  formData.append("isVisible", data.isVisible);
  formData.append("isFeatured", data.isFeatured);
  formData.append("isDeleted", data.isDeleted);
  formData.append("endorsementStatementId", data.statementId);
  formData.append("userId", data.userId);

  try {
    const response = await CandidateRepository.SaveEndorsementRow(formData);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
