import SettingsService from "@/services/SettingsService";
export default {
  async getBoardMembers(context) {
    const response1 = await SettingsService.GetBoardMembers();
    const boardMembers = response1.data;
    context.commit("SET_BOARDMEMBERS", boardMembers);
  },
  async getOfficers(context) {
    const response2 = await SettingsService.GetOfficers();
    const officers = response2.data;
    context.commit("SET_OFFICERS", officers);
  },
};
