import axios from "axios";

const resource = "api/public";

export default {
  VerifyRecaptcha: token => {
    return axios.post(`${resource}/recaptcha?token=${token}`);
  },
  AuthenticateMyWgaUser: data => {
    return axios.get(
      `sso/auth/?returnUrl=${data.redirect}&positionId=${data.positionId}`
    );
  }
};
