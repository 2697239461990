<template>
  <div class="admin-sidenav">
    <b-card>
      <!-- Title -->
      <b-card-title>Complete Your Website</b-card-title>
      <!-- Progress Steps -->
      <div class="accordion" role="tablist">
        <!-- Website Theme -->
        <div role="tab">
          <a class="section-title" href="#" role="tab" v-b-toggle.section-1>
            <span>Website Theme</span>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="float-right" />
          </a>
        </div>
        <b-collapse id="section-1" :visible="onTheme" accordion="my-accordion" role="tabpanel">
          <ul class="section-list">
            <li>
              <router-link to="theme">
                <font-awesome-icon :icon="checkMilestone(candidateModel.profileTheme)" />
                <span>Templates <span class="asterisk">*</span></span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <hr />
        <!-- Personal Information -->
        <div role="tab">
          <a class="section-title" href="#" role="tab" v-b-toggle.section-2>
            <span>Personal Information</span>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="float-right" />
          </a>
        </div>
        <b-collapse id="section-2" accordion="my-accordion" role="tabpanel" :visible="onContactInfo">
          <ul class="section-list">
            <li>
              <router-link to="contact-email">
                <font-awesome-icon :icon="checkMilestone(candidateModel.contactEmail)" />
                <span>Your Email <span class="asterisk">*</span></span>
              </router-link>
            </li>
            <li>
              <router-link to="name-position">
                <font-awesome-icon :icon="checkMilestone(candidateModel.name) &&
                  checkMilestone(candidateModel.positionId)
                  " />
                <span>Name & Position <span class="asterisk">*</span></span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <hr />
        <!-- Website Content -->
        <div role="tab">
          <a class="section-title" href="#" role="tab" v-b-toggle.section-3>
            <span>Website Content</span>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="float-right" />
          </a>
        </div>
        <b-collapse id="section-3" accordion="my-accordion" role="tabpanel" :visible="onAbout">
          <ul class="section-list">
            <li>
              <router-link to="intro-text">
                <font-awesome-icon :icon="checkMilestone(candidateModel.introText)" />
                <span>Intro Text</span>
              </router-link>
            </li>
            <li>
              <router-link to="open-content">
                <font-awesome-icon :icon="checkMilestone(candidateModel.openContent)" />
                <span>Main Body</span>
              </router-link>
            </li>
            <li>
              <router-link to="feature-text">
                <font-awesome-icon :icon="checkMilestone(candidateModel.featureText) &&
                  checkMilestone(candidateModel.featureTitle)
                  " />
                <span>Additional Content</span>
              </router-link>
            </li>
            <li>
              <router-link to="add-media-block">
                <font-awesome-icon :icon="checkMilestone(candidateModel.medias)" />
                <span>Photos & Videos</span>
              </router-link>
            </li>
            <li v-if="candidateModel.medias.length > 0">
              <router-link to="manage-media">
                <font-awesome-icon :icon="checkMilestone(candidateModel.medias)" />
                <span>Manage Photos & Videos</span>
              </router-link>
            </li>
            <li>
              <router-link to="social-links">
                <font-awesome-icon :icon="checkSocialLinks(candidateModel.socialLinks)" />
                <span>Social Links</span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <hr />
        <!-- Events -->
        <div role="tab">
          <a class="section-title" href="#" role="tab" v-b-toggle.section-4>
            <span>Events</span>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="float-right" />
          </a>
        </div>
        <b-collapse id="section-4" :visible="onEvents" accordion="my-accordion" role="tabpanel">
          <ul class="section-list">
            <li>
              <router-link to="add-event">
                <font-awesome-icon :icon="checkMilestone(candidateModel.events)" />
                <span>Create Events</span>
              </router-link>
            </li>
            <li v-if="candidateModel.events.length > 0">
              <router-link to="manage-events">
                <font-awesome-icon :icon="checkMilestone(candidateModel.events)" />
                <span>Manage Events</span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <hr />
        <!-- Endorsements -->
        <div>
          <div role="tab">
            <a class="section-title" href="#" role="tab" v-b-toggle.section-5>
              <span>Endorsements</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="float-right" />
            </a>
          </div>
          <b-collapse id="section-5" :visible="onEndorsements" accordion="my-accordion" role="tabpanel">
            <ul class="section-list">
              <li>
                <router-link to="add-endorsement">
                  <font-awesome-icon :icon="checkMilestone(candidateModel.endorsementStatements)" />
                  <span>Non-Candidate Statement</span>
                </router-link>
              </li>
              <li v-if="candidateModel.manageEndorsementList.length > 0">
                <router-link to="manage-endorsements">
                  <font-awesome-icon :icon="checkMilestone(candidateModel.endorsementGroups)" />
                  <span>Manage Endorsements</span>
                </router-link>
              </li>
              <li v-if="candidateModel.manageEndorsementList.length > 0">
                <router-link to="election-book">
                  <font-awesome-icon :icon="checkMilestone(candidateModel.endorsementGroups)" />
                  <span>Election Book</span>
                </router-link>
              </li>
            </ul>
          </b-collapse>
        </div>
        <!-- Petition for Candidacy -->
        <div v-if="candidateModel.isPetition">
          <hr />
          <div role="tab">
            <a class="section-title" href="#" role="tab" v-b-toggle.section-6>
              <span>Petition for Candidacy</span>
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="float-right" />
            </a>
          </div>
          <b-collapse id="section-6" :visible="onPetition" accordion="my-accordion" role="tabpanel">
            <ul class="section-list">
              <li>
                <router-link to="manage-petition">
                  <font-awesome-icon :icon="['far', 'circle']" />
                  <span>Manage Signatures</span>
                </router-link>
              </li>
            </ul>
          </b-collapse>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  data() {
    return {
      profile: {},
    };
  },
  mixins: [CandidateMixin],
  computed: {
    onTheme() {
      return this.$route.name.toLowerCase() === "theme";
    },
    onContactInfo() {
      let routes = [`contact-email`, `name-position`];
      return routes.includes(this.$route.name);
    },
    onAbout() {
      let routes = [
        `intro-text`,
        `open-content`,
        `feature-text`,
        `manage-media`,
        `add-media-block`,
        `social-links`,
      ];
      return routes.includes(this.$route.name);
    },
    onEvents() {
      let routes = [`add-event`, `manage-events`];
      return routes.includes(this.$route.name);
    },
    onEndorsements() {
      let routes = [
        `add-endorsement`,
        `manage-endorsements`,
        `approved-endorsements`,
        `election-book`,
      ];
      return routes.includes(this.$route.name);
    },
    onPetition() {
      let routes = [`manage-petition`];
      return routes.includes(this.$route.name);
    },
  },
  async mounted() {
    this.profile = await this.$store.dispatch(
      "candidates/getCandidate",
      this.$route.params.id
    );
  },
  methods: {
    checkMilestone: (prop) => {
      if (Array.isArray(prop)) {
        return prop.length > 0 ? ["fas", "check-circle"] : ["far", "circle"];
      } else return prop ? ["fas", "check-circle"] : ["far", "circle"];
    },
    checkSocialLinks: (links) => {
      return links.facebookLink == null &&
        links.twitterLink == null &&
        links.instagramLink == null &&
        links.linkedInLink == null &&
        links.personalWebsiteLink == null &&
        links.fawLink == null
        ? ["far", "circle"]
        : ["fas", "check-circle"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-sidenav {
  .card {
    .card-body {
      padding: 15px;

      .card-title {
        font-family: $montserrat;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .section-title {
      span {
        font-weight: 600;
        color: $black;
      }

      .fa-chevron-up,
      .fa-chevron-down {
        color: $mediumgray;
      }
    }

    .section-list {
      margin-top: 10px;
      margin-left: 25px;
      font-size: 14px;

      li {
        position: relative;

        svg {
          left: -25px;
          top: 3px;
          position: absolute;
          text-align: center;
          width: 20px;
        }

        a {
          color: $mediumgray;

          &:hover {
            color: #007bff;
          }
        }

        .router-link-active {
          font-weight: 600;
        }

        .fa-check-circle {
          color: $green;
        }

        .fa-circle {
          color: $mediumgray;
        }
      }
    }

    @media (max-width: $md-width) {
      & {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
