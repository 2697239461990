import Vue from "vue";
import Vuex from "vuex";
import authModule from "./modules/authModule.js";
import candidatesModule from "./modules/candidates/index.js";
import settingsModule from "./modules/settings/index.js";
import homeModule from "./modules/home/index.js";
import VuexPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authModule,
    candidates: candidatesModule,
    settings: settingsModule,
    home: homeModule,
  },
  plugins: [
    VuexPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
