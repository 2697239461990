import axios from "axios";
import { authHeader } from "./authHeader";
import { ExtractTiktok } from "@/services/helpers";

class CandidateService {
  async get(userId) {
    const response = await axios.get("api/candidate/" + userId, {
      headers: authHeader()
    });

    if (response.data.success) {
      let extras = [];
      let videos = response.data.data.medias.filter(function(video) {
        return video.mediaType == 2;
      });

      for (let index in videos) {
        let video = videos[index];

        if (video.path.includes("tiktok")) {
          const result = await ExtractTiktok(video.path);
          extras.push({
            id: video.id,
            detail: result
          });
        } else if (video.path.includes("instagram")) {
          extras.push({
            id: video.id,
            detail: null
          });
        }
      }

      response.data.data.medias.map(media => {
        if (media.mediaType == 2) {
          if (media.path.includes("tiktok")) {
            if (media.id) {
              media.extras = {
                type: "tiktok",
                detail: extras.filter(x => x.id == media.id)[0].detail
              };
            }
          } else if (media.path.includes("instagram")) {
            if (media.id) {
              media.extras = {
                type: "instagram",
                detail: extras.filter(x => x.id == media.id)[0].detail
              };
            }
          }
        }
      });

      return response.data;
    } else {
      throw response.data.message;
    }
  }
  async getPublished(userId) {
    const response = await axios.get("api/candidate/published/" + userId, {
      headers: authHeader()
    });

    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }
  async publishCandidate(userId) {
    const response = await axios.post("api/candidate/publish", null, {
      headers: authHeader(),
      params: { userId }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async deleteCandidate(userId) {
    const response = await axios.delete(`api/candidate/${userId}`, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveTheme(userId, themeId, colorId) {
    const response = await axios.post("api/candidate/theme", null, {
      headers: authHeader(),
      params: { userId, themeId, colorId }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveContactEmail(userId, email) {
    const response = await axios.post("api/candidate/contact-email", null, {
      headers: authHeader(),
      params: { userId, email }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveSocialLinks(data) {
    const response = await axios.post("api/candidate/social-links", data, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveNamePosition(data) {
    let formData = new FormData();
    formData.append("userId", data.userId);
    formData.append("name", data.name);
    formData.append("position", data.position);

    if (data.headshot) formData.append("headshot", data.headshot);
    if (data.headshotCurrentUrl)
      formData.append("headshotCurrentUrl", data.headshotCurrentUrl);
    const response = await axios.post("api/candidate/name-position", formData, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveIntroText(userId, text) {
    const response = await axios.post("api/candidate/intro-text", null, {
      headers: authHeader(),
      params: { userId, text }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveOpenContent(userId, text) {
    let data = {
      userId: userId,
      openText: text
    };
    const response = await axios.post("api/candidate/open-content", data, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveFeatureText(userId, title, text) {
    const response = await axios.post("api/candidate/feature", null, {
      headers: authHeader(),
      params: { userId, title, text }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveEvent(data) {
    let formData = new FormData();
    formData.append("userId", data.userId);
    formData.append("title", data.title);
    formData.append("date", data.date);
    formData.append("time", data.time);
    formData.append("description", data.description);
    formData.append("ctaText", data.ctaText);
    formData.append("ctaLink", data.ctaLink);
    formData.append("status", data.status);
    formData.append("isVisible", data.visible);
    if (data.id) formData.append("id", data.id);
    if (data.existingPhotoUrl)
      formData.append("coverPhotoUrl", data.existingPhotoUrl);
    if (data.coverPhoto) formData.append("coverPhoto", data.coverPhoto);

    const response = await axios.post("api/candidate/event", formData, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async getPositions() {
    const response = await axios.get("api/candidate/positions", {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveMedia(data) {
    let formData = new FormData();

    formData.append("userId", data.userId);

    data.photos.forEach(e => {
      if (e.file) formData.append("photos", e.file);
    });

    data.videos.forEach(e => {
      if (e.url) formData.append("videoUrls", e.url);
    });

    try {
      const response = await axios.post("api/candidate/media", formData, {
        headers: authHeader()
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw response.data.message;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async saveMediaTableAsync(data) {
    try {
      const response = await axios.post("api/candidate/media-table", data, {
        headers: authHeader()
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
  }

  async saveEventsTableAsync(data) {
    const response = await axios.post("api/candidate/events-table", data, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async saveEndorsementsTableAsync(data) {
    const response = await axios.post(
      "api/candidate/endorsements-table",
      data,
      {
        headers: authHeader()
      }
    );
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async getAllCandidatesAsync() {
    const response = await axios.get("api/candidate/all-candidates", {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async getCandidateByNameAsync(data) {
    try {
      const response = await axios.get(`api/public/uri/${data}`, {
        headers: authHeader()
      });
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
  }
  async getCandidatePreviewByNameAsync(data) {
    try {
      const response = await axios.get(
        `api/candidate/preview-candidate/${data}`,
        {
          headers: authHeader()
        }
      );
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
  }

  async saveEndorsementText(data) {
    const response = await axios.post("api/candidate/endorsement-text", data, {
      headers: authHeader()
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async submitEndorsement(endorsement) {
    try {
      const response = await axios.post(
        `api/Public/submit-endorsement`,
        endorsement,
        {
          headers: authHeader()
        }
      );

      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
  }

  async getEndorsementPositionsAsync() {
    try {
      let response = await axios.get(`api/public/positions`, {
        headers: authHeader()
      });
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e.response);
    }
  }

  async submitEndorsementsToAdmin(userId) {
    const response = await axios.post(`api/export/submit-unapproved`, null, {
      headers: authHeader(),
      params: { userId: userId }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }

  async submitPetitionsToAdmin(userId) {
    const response = await axios.post(`api/export/submit-petitions`, null, {
      headers: authHeader(),
      params: { userId: userId }
    });
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }
  async saveEndorsementGroup(data) {
    let formData = new FormData();
    formData.append("candidateId", data.candidateId);
    formData.append("title", data.title);
    formData.append("id", data.date);
    formData.append("order", data.order);
    const response = await axios.post(
      "api/candidate/save-endorsement-group",
      formData,
      {
        headers: authHeader()
      }
    );
    if (response.data.success) {
      return response.data;
    } else {
      throw response.data.message;
    }
  }
}

export default new CandidateService();
