import axios from "axios";
import { authHeader } from "@/services/authHeader";

const resource = "api/candidate";

export default {
  GetUser: userId => {
    return axios.get(`${resource}/${userId}`, {
      headers: authHeader()
    });
  },

  GetCandidateByName: name => {
    return axios.get(`api/public/uri/${name}`, {
      headers: authHeader()
    });
  },

  GetPositions: () => {
    return axios.get(`${resource}/positions`, {
      headers: authHeader()
    });
  },

  SubmitExportedEndorsements: data => {
    return axios.post(`${resource}/submit-exported-endorsements`, data, {
      headers: authHeader()
    });
  },

  SaveTheme: data => {
    return axios.post(`${resource}/theme`, null, {
      headers: authHeader(),
      params: {
        userId: data.userId,
        themeId: data.themeId,
        colorId: data.colorId
      }
    });
  },

  SaveEmail: data => {
    return axios.post(`${resource}/contact-email`, null, {
      headers: authHeader(),
      params: { userId: data.userId, email: data.email }
    });
  },

  SaveSocialLinks: data => {
    return axios.post(`${resource}/social-links`, data, {
      headers: authHeader()
    });
  },

  SaveNamePosition: data => {
    return axios.post(`${resource}/name-position`, data, {
      headers: authHeader()
    });
  },

  SaveIntroText: data => {
    return axios.post(`${resource}/intro-text`, null, {
      headers: authHeader(),
      params: { userId: data.userId, text: data.text }
    });
  },

  SaveOpenContent: data => {
    return axios.post(`${resource}/open-content`, data, {
      headers: authHeader()
    });
  },

  SaveFeatureText: data => {
    return axios.post(`${resource}/feature`, data, {
      headers: authHeader()
    });
  },

  SaveMedia: data => {
    return axios.post(`${resource}/media`, data, {
      headers: authHeader()
    });
  },

  SaveMediaTable: data => {
    return axios.post(`${resource}/media-table`, data, {
      headers: authHeader()
    });
  },

  SaveEvent: data => {
    return axios.post(`${resource}/event`, data, {
      headers: authHeader()
    });
  },

  SaveEventTable: data => {
    return axios.post(`${resource}/events-table`, data, {
      headers: authHeader()
    });
  },

  SaveEndorsementText: data => {
    return axios.post(`${resource}/endorsement-text`, data, {
      headers: authHeader()
    });
  },

  SaveEndorsementTable: data => {
    return axios.post(`${resource}/endorsements-table`, data, {
      headers: authHeader()
    });
  },

  SubmitEndorsement: data => {
    return axios.post(`api/public/submit-endorsement`, data);
  },

  SendFailedEndorsementEmail: data => {
    return axios.post(`api/public/send-failed-endorsement-email`, data);
  },

  HasEndorsed: data => {
    return axios.get(
      `api/public/has-endorsed?userid=${data.userId}&memberId=${data.memberId}`
    );
  },

  SubmitPetition: data => {
    return axios.post(`api/public/submit-petition`, data);
  },

  SubmitEmail: data => {
    return axios.post(`api/public/send-message`, data);
  },

  SubmitHelpMail: data => {
    return axios.post(`api/public/submit-help`, data);
  },

  UploadEditorImage: data => {
    let formData = new FormData();
    formData.append("image", data.image);
    return axios.post(
      `api/image/upload-editor-image/${data.userId}`,
      formData,
      {
        headers: authHeader()
      }
    );
  },

  SaveEndorsementGroup: data => {
    return axios.post(`${resource}/save-endorsement-group`, data, {
      headers: authHeader()
    });
  },
  SaveEndorsementRow: data => {
    return axios.post(`${resource}/save-endorsement-row`, data, {
      headers: authHeader()
    });
  },
  UpdateEndorsementGroup: data => {
    return axios.post(`${resource}/update-endorsement-group`, data, {
      headers: authHeader()
    });
  },
  DeleteEndorsementGroup: data => {
    return axios.post(`${resource}/delete-endorsement-group`, null, {
      headers: authHeader(),
      params: { groupId: data.id, userId: data.userId }
    });
  },
  UpdateLayoutPreference: data => {
    return axios.post(`${resource}/update-layout-preference`, null, {
      headers: authHeader(),
      params: { layout: data.layout, userId: data.userId }
    });
  },
  UpdateAutoSendNonCandidateStatements: data => {
    return axios.post(
      `${resource}/update-auto-send-non-candidate-statements`,
      null,
      {
        headers: authHeader(),
        params: { autoSend: data.autoSend, userId: data.userId }
      }
    );
  },
  RemoveFeaturedEndorsementGroupItem: data => {
    return axios.post(
      `${resource}/remove-featured-endorsement-group-item`,
      null,
      {
        headers: authHeader(),
        params: {
          groupId: data.groupId,
          statementId: data.statementId,
          publicEndorsementId: data.publicEndorsementId,
          userId: data.userId
        }
      }
    );
  }
};
