import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import axios from "axios";
import VueGtag from "vue-gtag";

import AdminHeader from "@/components/admin/Header.vue";
import AdminBreadcrumb from "@/components/admin/Breadcrumb.vue";
import AdminSideNav from "@/components/admin/SideNav.vue";
import AdminDisclaimer from "@/components/admin/Disclaimer.vue";
import AdminFooterButton from "@/components/admin/FooterButton.vue";
import ModalThemePreview from "@/components/admin/ModalThemePreview.vue";
import ModalThemeGuide from "@/components/admin/ModalThemeGuide.vue";
import FullEndorsementList from "@/components/admin/FullEndorsementList.vue";
import EndorsementGroups from "@/components/admin/EndorsementGroups.vue";
import VueMeta from "vue-meta";
import VueHighlights, { autoLink, autoHighlight } from "vue-highlights";

Vue.use(VueMeta);
Vue.component(VueHighlights.name, VueHighlights);

axios.defaults.baseURL = process.env.API;

// Need to refactor this later to only add what we need
library.add(far, fas, fab);

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(
  VueGtag,
  {
    config: { id: "G-FM6JVVVE3B" },
  },
  router
);

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("en", en);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("AdminHeader", AdminHeader);
Vue.component("AdminBreadcrumb", AdminBreadcrumb);
Vue.component("AdminSideNav", AdminSideNav);
Vue.component("AdminDisclaimer", AdminDisclaimer);
Vue.component("AdminFooterButton", AdminFooterButton);
Vue.component("ModalThemePreview", ModalThemePreview);
Vue.component("ModalThemeGuide", ModalThemeGuide);
Vue.component("FullEndorsementList", FullEndorsementList);
Vue.component("EndorsementGroups", EndorsementGroups);

//filters
Vue.filter("formatDate", (value) => {
  const date = new Date(value);
  const dateString = date.toLocaleString(["en-US"], {
    day: "numeric", // numeric, 2-digit
    year: "numeric", // numeric, 2-digit
    month: "long", // numeric, 2-digit, long, short, narrow
  });
  return dateString;
});

Vue.filter("formatTime", (value) => {
  const date = new Date(value);
  return date.toLocaleString(["en-US"], {
    hour: "numeric", // numeric, 2-digit
    minute: "numeric", // numeric, 2-digit
    hour12: true,
  });
});

extend("ssnid", {
  ...rules.required,
  ...rules.numeric,
  validate: (params) => {
    let isValid = false;
    if (params) {
      let regex = /^\d*[.]?\d*$/;
      params = params.replace(/-/g, "");

      let minVal = params.length === 4;
      let maxVal = params.length === 8;
      let initialInt = parseInt(String(params).charAt(0)) === 8;

      if (!regex.test(params)) {
        isValid = false;
        return;
      }

      if (minVal) isValid = true;
      else if (maxVal && initialInt) isValid = true;
    }

    return new Promise((resolve) => {
      resolve({
        valid: isValid,
      });
    });
  },
  message: "Invalid {_field_}",
});

extend("url", {
  validate(value) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(value);
  },
  message: "Please enter a valid url.",
});

extend("strong_password", {
  validate: (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
  message:
    "The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
