import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
import Settings from "@/models/Settings";

export default {
  namespaced: true,
  state() {
    return {
      item: new Settings(),
      boardMembers: [],
      officers: [],
      positions: [],
      internalAccounts: [],
      excludedList:[]
    };
  },
  mutations,
  actions,
  getters,
};
