import CandidateRepository from "./CandidateRepository";
import ExportRepository from "./ExportRepository";
import AccountRepository from "./AccountRepository";
import SecurityRepository from "./SecurityRepository";
import SettingsRepository from "./SettingsRepository";

const repositories = {
  CandidateRepository: CandidateRepository,
  ExportRepository: ExportRepository,
  AccountRepository: AccountRepository,
  SecurityRepository: SecurityRepository,
  SettingsRepository: SettingsRepository,
};

export default {
  get: (repository) => repositories[repository],
};
