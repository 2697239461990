<template>
  <b-modal
    id="modal-theme-guide"
    :title="getTheme ? getTheme.title : ''"
    size="lg"
    scrollable
    hide-footer
  >
    <b-alert show variant="primary">{{
      getTheme ? getTheme.description : ""
    }}</b-alert>
    <b-img
      v-if="getTemplate"
      :src="require(`@/assets/images/admin/guide/${getTemplate}`)"
      alt="Theme Guide"
      rounded
      fluid
    ></b-img>
  </b-modal>
</template>

<script>
export default {
  props: {
    themeId: String,
    templateId: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      guide: [
        {
          id: "email",
          title: "Your Email",
          description: "This contact form will send messages to the email you provide.",
          img: {
            t1: "t1-email.png",
            t2: "t2-email.png",
            t3: "t3-email.png",
            t4: "t4-email.png"
          }
        },
        {
          id: "name",
          title: "Name",
          description: "This is where your name will be displayed.",
          img: {
            t1: "t1-name.png",
            t2: "t2-name.png",
            t3: "t3-name.png",
            t4: "t4-name.png"
          }
        },
        {
          id: "position",
          title: "Position",
          description: "This is where your position will be displayed.",
          img: {
            t1: "t1-position.png",
            t2: "t2-position.png",
            t3: "t3-position.png",
            t4: "t4-position.png"
          }
        },
        {
          id: "headshot",
          title: "headshot",
          description: "This is where your headshot will be displayed.",
          img: {
            t1: "t1-headshot.png",
            t2: "t2-headshot.png",
            t3: "t3-headshot.png",
            t4: "t4-headshot.png"
          }
        },
        {
          id: "homepage-headshot",
          title: "headshot",
          description: "This is where your WGAW Elections Landing Page headshot will be displayed.",
          img: {
            t1: "t1-home-headshot.png",
            t2: "t1-home-headshot.png",
            t3: "t1-home-headshot.png",
            t4: "t1-home-headshot.png"
          }
        },
        {
          id: "introText",
          title: "Intro Text",
          description: "This is where your intro text will be displayed.",
          img: {
            t1: "t1-intro-text.png",
            t2: "t2-intro-text.png",
            t3: "t3-intro-text.png",
            t4: "t4-intro-text.png"
          }
        },
        {
          id: "openContent",
          title: "Main Body",
          description: "This is where your main body will be displayed.",
          img: {
            t1: "t1-open-content.png",
            t2: "t2-open-content.png",
            t3: "t3-open-content.png",
            t4: "t4-open-content.png"
          }
        },
        {
          id: "featureText",
          title: "Additional Content",
          description: "This is where your additional content will be displayed.",
          img: {
            t1: "t1-feature-text.png",
            t2: "t2-feature-text.png",
            t3: "t3-feature-text.png",
            t4: "t4-feature-text.png"
          }
        },
        {
          id: "featureTitle",
          title: "Additional Content Title",
          description: "This is where your additional content title will be displayed.",
          img: {
            t1: "t1-feature-text.png",
            t2: "t2-feature-text.png",
            t3: "t3-feature-text.png",
            t4: "t4-feature-text.png"
          }
        },
        {
          id: "featureImage",
          title: "Additional Content Image",
          description: "This is where your additional content image will be displayed.",
          img: {
            t1: "t1-feature-image.png",
            t2: "t2-feature-image.png",
            t3: "t3-feature-image.png",
            t4: "t4-feature-image.png"
          }
        },
        {
          id: "mediaBlock",
          title: "Photos & Video",
          description: "This is where your photos and video will be displayed.",
          img: {
            t1: "t1-media-block.png",
            t2: "t2-media-block.png",
            t3: "t3-media-block.png",
            t4: "t4-media-block.png"
          }
        },
        {
          id: "socialLinks",
          title: "Social Links",
          description: "This is where your social links will be displayed.",
          img: {
            t1: "t1-social-links.png",
            t2: "t2-social-links.png",
            t3: "t3-social-links.png",
            t4: "t4-social-links.png"
          }
        },
        {
          id: "events",
          title: "Events",
          description: "This is where your events will be displayed.",
          img: {
            t1: "t1-events.png",
            t2: "t2-events.png",
            t3: "t3-events.png",
            t4: "t4-events.png"
          }
        },
        {
          id: "endorsementStatement",
          title: "Non-Candidate Statement",
          description:
            "This is where your non-candidate statement will be displayed.",
          img: {
            t1: "t1-endorsement-statement.png",
            t2: "t2-endorsement-statement.png",
            t3: "t3-endorsement-statement.png",
            t4: "t4-endorsement-statement.png"
          }
        },
        {
          id: "endorsementList",
          title: "Endorsement List",
          description: "This is where your endorsement list will be displayed.",
          img: {
            t1: "t1-endorsement-list.png",
            t2: "t2-endorsement-list.png",
            t3: "t3-endorsement-list.png",
            t4: "t4-endorsement-list.png"
          }
        },
        {
          id: "petition",
          title: "Petition",
          description:
            "This is where your petition statement will be displayed.",
          img: {
            t1: "t1-petition.png",
            t2: "t2-petition.png",
            t3: "t3-petition.png",
            t4: "t4-petition.png"
          }
        }
      ]
    };
  },
  computed: {
    getTheme() {
      return this.guide.find(item => item.id == this.themeId);
    },
    getTemplate() {
      if (this.getTheme) {
        switch (this.templateId) {
          case 1:
            return this.getTheme.img.t1;
          case 2:
            return this.getTheme.img.t2;
          case 3:
            return this.getTheme.img.t3;
          case 4:
            return this.getTheme.img.t4;
          default:
            return this.getTheme.img.t1;
        }
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
