class Candidate {
  constructor({
    id = 0,
    userId = 0,
    name = null,
    contactEmail = null,
    positionId = 0,
    profileTheme = 0,
    profileColor = 0,
    featureTitle = null,
    featureText = null,
    introText = null,
    openContent = null,
    headshotPhotoPath = null,
    homePageHeadshotPhotoPath = null,
    featurePhotoPath = null,
    publishedSinceLastSave = false,
    hasPublishedContent = false,
    slug = null,
    isPetition = false,
    socialLinks = [],
    medias = [],
    events = [],
    endorsements = [],
    endorsementStatements = [],
    publishedEndorsementStatements = [],
    petitions = [],
    endorsementStatementsTableItems = [],
    requiredSignatures = 0,
    endorsementGroups = [],
    //endorsementGroupItems = [],
    endorsementLayout = 0,
    manageEndorsementList = [],
    autoSendNonCandidateStatementsToAdmin = false
  } = {}) {
    this.id = id;
    this.userId = userId;
    this.name = name;
    this.contactEmail = contactEmail;
    this.positionId = positionId;
    this.profileTheme = profileTheme;
    this.profileColor = profileColor;
    this.featureTitle = featureTitle;
    this.featureText = featureText;
    this.introText = introText;
    this.openContent = openContent;
    this.headshotPhotoPath = headshotPhotoPath;
    this.homePageHeadshotPhotoPath = homePageHeadshotPhotoPath;
    this.featurePhotoPath = featurePhotoPath;
    this.publishedSinceLastSave = publishedSinceLastSave;
    this.hasPublishedContent = hasPublishedContent;
    this.slug = slug;
    this.isPetition = isPetition;
    this.socialLinks = socialLinks;
    this.medias = medias;
    this.events = events;
    this.endorsements = endorsements;
    this.endorsementStatements = endorsementStatements;
    this.publishedEndorsementStatements = publishedEndorsementStatements;
    this.petitions = petitions;
    this.endorsementStatementsTableItems = endorsementStatementsTableItems;
    this.requiredSignatures = requiredSignatures;
    this.endorsementGroups = endorsementGroups;
    //this.endorsementGroupItems = endorsementGroupItems;
    this.endorsementLayout = endorsementLayout;
    this.manageEndorsementList = manageEndorsementList;
    this.autoSendNonCandidateStatementsToAdmin = autoSendNonCandidateStatementsToAdmin;
  }
}

export default Object.freeze(Candidate);
