<template>
  <div class="admin-header">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-container>
        <b-navbar-brand href="#"> WGAW Candidate Admin </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-show="isLoggedIn" href="#">
              <font-awesome-icon icon="user" />
              {{ loggedInUsername }}
            </b-nav-item>
            <b-nav-item-dropdown v-show="isAdmin" text="Manage">
              <template slot="button-content">
                <font-awesome-icon icon="users-cog" fixed-width /> Manage
              </template>
              <b-dropdown-item to="/admin/manage-homepage"><font-awesome-icon icon="home" fixed-width /> Manage
                Homepage</b-dropdown-item>
              <b-dropdown-item :to="`/admin/${this.loggedInUserId}/manage-users`"><font-awesome-icon icon="users"
                  fixed-width /> Manage
                Users</b-dropdown-item>
              <b-dropdown-item to="/admin/candidate-endorsements"><font-awesome-icon icon="file-excel" fixed-width />
                Candidate
                Endorsements</b-dropdown-item>
              <b-dropdown-item to="/admin/manage-settings"><font-awesome-icon icon="cog" fixed-width /> Manage
                Settings</b-dropdown-item>
                <b-dropdown-item to="/admin/exclusion-list"><font-awesome-icon icon="cog" fixed-width /> Manage
                  Non-Eligible List</b-dropdown-item>
              <b-dropdown-item to="/register"><font-awesome-icon icon="user-plus" fixed-width />
                Register</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item href="#" @click="onHelp(true)">
              <font-awesome-icon icon="life-ring" fixed-width />
              Help
            </b-nav-item>
            <b-nav-item v-show="isLoggedIn" @click="onLogout">
              <font-awesome-icon icon="sign-out-alt" fixed-width />
              Logout
            </b-nav-item>
            <!-- <b-nav-item v-show="!isLoggedIn">
              <font-awesome-icon icon="sign-in-alt" />
              <router-link to="/login">Login</router-link>
            </b-nav-item> -->
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <help-dialog :dialog="helpDialog" @hide="onHelp(false)"></help-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/common/HelpDialog";
import Candidate from "@/models/Candidate";
export default {
  components: {
    HelpDialog,
  },
  data() {
    return {
      isLoggedIn: false,
      loggedInUserId: "",
      isAdmin: false,
      loggedInUsername: "",
      helpDialog: false,
    };
  },
  created() {
    this.isLoggedIn = this.$store.getters["auth/isAuthenticated"];
    if (this.isLoggedIn) {
      this.isAdmin = this.$store.state.auth.user.roles.includes("Admin");
      this.loggedInUserId = this.$store.state.auth.user.id;
      this.loggedInUsername = this.$store.state.auth.user.email;
    }
  },
  methods: {
    onLogout() {
      this.$store.commit("candidates/SET_CANDIDATE", new Candidate());
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    onManage() {
      window.location.href = `/admin/${this.loggedInUserId}/manage-users`;
    },
    onRegister() {
      window.location.href = `/register`;
    },
    onHelp(args) {
      this.helpDialog = args;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/admin/_base.scss";

.admin-header {
  .navbar {
    min-height: 80px;
    background: linear-gradient(45deg, rgba(33, 150, 243, 0.85) 1%, rgba(76, 175, 80, 0.85) 100%);

    .navbar-brand {
      font-family: $montserrat;
      font-weight: 600;
    }

    .nav-link {
      color: $white;
    }

    .dropdown-menu {
      .dropdown-item {
        color: $blue;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
