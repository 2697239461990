export default {
  async mounted() {
    await this.$store.dispatch("settings/getSettings");
  },
  computed: {
    settings() {
      return this.$store.state.settings.item;
    },
  },
};
