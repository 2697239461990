import SettingsService from "../../../services/SettingsService";
import CandidateService from "../../../services/candidateService";
export default {
  async getSettings(context) {
    const responseData = await SettingsService.GetSettings();
    context.commit("SET_SETTINGS", responseData.data);
  },
  async getExcludedList(context) {
    const responseData = await SettingsService.GetExcludedList();
    return responseData;
  },
  async saveExcludedList(context, data) {
    const responseData = await SettingsService.SaveExcludedList(data);  
    return responseData;  
  },
  async deleteExcludedList(context, data) {
    await SettingsService.DeleteExcludedList(data);
  },
  async updateSettings(context, data) {
    const responseData = await SettingsService.UpdateSettings(data);
    context.commit("SET_SETTINGS", responseData.data);
  },
  async updateBoardMembers(context, data) {
    const responseData = await SettingsService.UpdateBoardMembers(data);
    context.commit("SET_BOARDMEMBERS", responseData.data);
  },
  async updateOfficers(context, data) {
    const responseData = await SettingsService.UpdateOfficers(data);
    context.commit("SET_OFFICERS", responseData.data);
  },
  async getHomePageCandidates(context) {
    const response1 = await SettingsService.GetBoardMembers();
    const response2 = await SettingsService.GetOfficers();
    const officers = response2.data;
    const boardMembers = response1.data;

    context.commit("SET_OFFICERS", officers);
    context.commit("SET_BOARDMEMBERS", boardMembers);
  },
  async saveHomePageCandidate(context, data) {
    await SettingsService.SaveHomePageCandidate(data);
  },
  async deleteHomePageCandidate(context, data) {
    await SettingsService.DeleteHomePageCandidate(data);
  },
  async getPositions(context) {
    const responseData = await CandidateService.getPositions();
    context.commit("SET_POSITIONS", responseData.data);
  },
  async getInternalAccounts(context) {
    const responseData = await CandidateService.getAllCandidatesAsync();
    context.commit("SET_INTERNAL_ACCOUNTS", responseData.data);
  }
};
