import Repository from "@/repositories/RepositoryFactory";

const SettingsRepository = Repository.get("SettingsRepository");
class SettingsService {
  UpdateSettings = async (data) => {
    try {
      const response = await SettingsRepository.UpdateSettings(data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  GetSettings = async () => {
    try {
      const response = await SettingsRepository.GetSettings();
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  UpdateBoardMembers = async (data) => {
    try {
      const response = await SettingsRepository.UpdateBoardMembers(data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  UpdateOfficers = async (data) => {
    try {
      const response = await SettingsRepository.UpdateOfficers(data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  GetOfficers = async () => {
    try {
      const response = await SettingsRepository.GetOfficers();
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  GetBoardMembers = async () => {
    try {
      const response = await SettingsRepository.GetBoardMembers();
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  SaveHomePageCandidate = async (data) => {
    try {
      const response = await SettingsRepository.SaveHomePageCandidate(data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  DeleteHomePageCandidate = async (id) => {
    try {
      const response = await SettingsRepository.DeleteHomePageCandidate(id);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };

  GetExcludedList= async () => {
    try {
      const response = await SettingsRepository.GetExcludedList();
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
  SaveExcludedList=async (data) => {
    try {
      const response = await SettingsRepository.SaveExcludedList(data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
  DeleteExcludedList = async (id) => {
    try {
      const response = await SettingsRepository.DeleteExcludedList(id);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }; 
}
export default new SettingsService();
