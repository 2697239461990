<template>
  <div class="endorsement-group">
    <b-input v-model="newGroup" placeholder="Group Name" />
    <b-button @click="saveGroup">Create</b-button>
    <div v-for="(group, index) in groups" :key="index" class="mt-2">
      <span
        ><h4>{{ group.title }}</h4></span
      >
      <b-table
        striped
        responsive
        ref="table"
        :fields="fields"
        :items="getGroupItems(group.id)"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        class="reorderable mb-4"
        v-sortable="sortableOptions"
        thead-class="d-none"
      >
        <template #cell(id)="data">
          <b-icon
            icon="arrows-move"
            aria-hidden="true"
            :data-item-id="data.item.id"
          ></b-icon>
        </template>
        <template #cell(name)="data">
          <span> {{ data.item.firstName }} {{ data.item.lastName }}</span>
        </template>
        <template #cell(position)="data">
          <span
            class="key-color key-orange"
            v-if="colorCheck(1, data.value)"
          ></span>
          <span
            class="key-color key-red"
            v-if="colorCheck(2, data.value)"
          ></span>
          <span
            class="key-color key-green"
            v-if="colorCheck(3, data.value)"
          ></span>
          <span
            class="key-color key-yellow"
            v-if="colorCheck(4, data.value)"
          ></span>
          <span
            class="key-color key-purple"
            v-if="colorCheck(5, data.value)"
          ></span>
        </template>
        <template #cell(isFeatured)="data">
          <b-form-checkbox
            :checked="data.value"
            @change="toggleFeatured(data)"
            switch
          >
          </b-form-checkbox>
        </template>
      </b-table>
    </div>
    <div class="featured-without-group"></div>
  </div>
</template>

<script>
import { Sortable } from "sortablejs";
import CandidateMixin from "@/mixins/candidate.mixin";
import { setEndorsementPosition } from "@/services/helpers";
import * as CandidateService from "@/services/CandidateService2";

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options,
  });
};
export default {
  directives: {
    sortable: {
      name: "sortable",
      bind(el, binding, vnode) {
        const table = el;
        table._sortable = createSortable(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      },
    },
  },
  mixins: [CandidateMixin],
  mounted() {
    // this.groups = this.candidate.endorsementGroups;
    this.groups = [
      {
        id: 1,
        candidateId: 1,
        title: "Current Board Members",
        order: 1,
      },
      {
        id: 2,
        candidateId: 1,
        title: "Past Board Members",
        order: 2,
      },
    ];
    this.groupItems = this.candidate.endorsements;
  },
  data() {
    return {
      saving: false,
      newGroup: null,
      groups: [],
      groupItems: [],
      loading: false,
      exporting: false,
      errorMessage: null,
      saveText: "Save & Continue",
      hideSkip: false,
      endorsementsTable: [],
      sortBy: "tableOrder",
      sortDesc: false,
      fields: [
        {
          label: "",
          key: "id",
        },
        {
          label: "#",
          key: "tableOrder",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Position",
          key: "position",
        },
        {
          label: "Featured",
          key: "isFeatured",
        },
      ],
      sortableOptions: {
        chosenClass: "is-selected",
        onSort: function (/**Event*/ evt) {
          //   //Dragged item new position
          //   console.log(`New Position Index: ${evt.newIndex}`);
          //   console.log(`Old Position Index: ${evt.oldIndex}`);
          //   var itemEl = evt.item; // dragged HTMLElement
          //   //   console.log(itemEl, "onSort");
          //   console.log(evt);
        },
      },
      items: [],
      tableItems: [],
    };
  },
  computed: {
    candidate() {
      return this.candidateModel;
    },
  },
  watch: {
    candidate(newCandidate) {},
  },
  methods: {
    getGroupItems(groupId) {
      var items = this.groupItems.filter(function (item) {
        if (item.endorsementGroupIds) {
          return item.endorsementGroupIds.includes(groupId);
        }
      });

      return items;
    },
    toggleFeatured(data) {
      data.value = !data.value;
      data.item.featured = data.value;
      this.saveRowData(data);
    },
    saveRowData(data) {},
    saveGroup() {
      var data = {
        candidateId: 0,
        title: this.newGroup,
        order: 99,
      };

      CandidateService.SaveEndorsementGroupAsync(data)
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("candidates/SET_CANDIDATE", res.data);
            this.saving = false;
          }, 500);
        })
        .catch((e) => {
          this.saving = false;
          this.errorMessage = e;
        });
    },
    colorCheck(id, positions) {
      let pos = positions.split(",");
      pos = pos.map((x) => {
        return parseInt(x);
      });
      var hasPosition = pos.some((x) => x === id);
      return hasPosition;
    },
    checkEndorsementTable() {
      let vm = this;
      let endorsements = this.endorsements;
      let tables = document.querySelectorAll(".reorderable");

      for (let t = 0; t < tables.length; t++) {
        let tr = tables[t].querySelectorAll("tbody tr");
        let order = 1;
        for (let i = 0; i < tr.length; i++) {
          let td = tr[i].querySelectorAll("td")[1].innerHTML;
          let selectedAction = tr[i]
            .querySelectorAll("td")[3]
            .querySelector(".custom-select").value;
          if (endorsements[t].items[parseInt(td - 1)]) {
            endorsements[t].items[parseInt(td - 1)].order = order;
            endorsements[t].items[parseInt(td - 1)].action = selectedAction;
            order++;
          }
        }
      }

      endorsements.map(function (item) {
        return item.items.map((x) => {
          vm.tableItems.push({
            publicEndorsementId: x.publicEndorsementId,
            action: parseInt(x.action),
            order: parseInt(x.order),
            endorsementStatementId: x.endorsementStatementId,
          });
        });
      });
    },
  },
};
</script>
