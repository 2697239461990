import axios from "axios";
import { authHeader, authHeaderMultiForm } from "@/services/authHeader";
const resource = "api/manage";

export default {
  UpdateSettings: data => {
    return axios.post(`${resource}/settings`, data, {
      headers: authHeader()
    });
  },
  GetSettings: () => {
    return axios.get(`${resource}/settings`);
  },
  GetExcludedList: () => {
    return axios.get(`${resource}/excluded-list`, {
      headers: authHeader()
    });
  },
  SaveExcludedList: data => {
    return axios.post(`${resource}/excluded-list`, data, {
      headers: authHeader()
    });
  },
  DeleteExcludedList: id => {
    return axios.delete(`${resource}/excluded-list?id=${id}`, {
      headers: authHeader()
    });
  },
  UpdateBoardMembers: data => {
    return axios.post(`${resource}/board-members`, data, {
      headers: authHeader()
    });
  },
  UpdateOfficers: data => {
    return axios.post(`${resource}/officers`, data, {
      headers: authHeader()
    });
  },
  GetOfficers: () => {
    return axios.get(`${resource}/officers`);
  },
  GetBoardMembers: () => {
    return axios.get(`${resource}/board-members`);
  },
  SaveHomePageCandidate: data => {
    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("name", data.name);
    formData.append("position", data.position);
    formData.append("useInternalAccount", data.useInternalAccount);
    formData.append("internalAccountId", data.internalAccountId);
    formData.append("externalUrl", data.externalUrl);
    formData.append("order", data.order);

    if (data.headshot) formData.append("headshot", data.headshot);
    if (data.headshotUrl) formData.append("headshotUrl", data.headshotUrl);

    return axios.post(`${resource}/homepage-candidate`, formData, {
      headers: authHeader()
    });
  },
  DeleteHomePageCandidate: id => {
    return axios.get(`${resource}/delete-homepage-candidate/${id}`, {
      headers: authHeader()
    });
  },
  UploadCandidateEndorsements: data => {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("userId", data.userId);
    return axios.post(`${resource}/upload-candidate-endorsements`, formData, {
      headers: authHeaderMultiForm()
    });
  },
  DownloadEndorsementsTemplate: data => {
    axios
      .get(
        `${resource}/download-endorsements-template?userId=${data.userId}`,
        {
          headers: authHeader(),
          responseType: "blob"
        },
        {}
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${data.name}_CandidateEndorsements.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }
};
