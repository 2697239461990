import Candidate from "@/models/Candidate";
import * as CandidateService from "@/services/CandidateService2";
import { ExtractTiktok } from "@/services/helpers";

export default {
  data: () => ({
    candidateService: CandidateService,
    candidatePositions: [],
    errorMessage: null,
    saving: false,
  }),
  computed: {
    candidateModel() {
      const candidate = new Candidate(this.$store.state.candidates.candidate);
      const videos = candidate.medias.filter(function (item) {
        return item.mediaType == 2;
      });

      videos.forEach(async (v) => {
        if (v.path.includes("tiktok")) {
          const result = await ExtractTiktok(v.path);
          v.extras = {
            type: "tiktok",
            detail: result,
          };
        }
      });
      return candidate;
    },
    candidateTemp() {
      return new Candidate(this.$store.state.candidates.candidate);
    },
    hasUnsubmittedItems() {
      let result = false;
      let endorsements =
        this.candidateModel.endorsementStatementsTableItems.map(function (
          statement
        ) {
          return {
            tableItems: statement.items.filter(
              (item) => item.endorsementStatus !== "Submitted"
            ),
          };
        });
      for (let element of endorsements) {
        result = element.tableItems.length > 0;
        if (result) break;
      }
      return result;
    },
    hasSubmittedItems() {
      let result = false;
      let endorsements =
        this.candidateModel.endorsementStatementsTableItems.map(function (
          statement
        ) {
          return {
            tableItems: statement.items.filter(
              (item) => item.endorsementStatus === "Submitted"
            ),
          };
        });
      for (let element of endorsements) {
        result = element.tableItems.length > 0;
        if (result) break;
      }
      return result;
    },
  },
  created() {
    this.loadPositions();
  },
  methods: {
    loadPositions: function () {
      this.candidateService
        .GetPositionsAsync()
        .then((res) => {
          res.data.forEach((p) =>
            this.candidatePositions.push({ value: p.id, text: p.name })
          );
        })
        .catch((e) => {
          this.errorMessage = e;
        });
    },
  },
};
