<template>
  <div class="admin-footer-button">
    <b-row>
      <b-col md="3">
        <b-button
          variant="outline-secondary"
          @click="onBack"
          v-show="!hideBackButton"
          :disabled="saving"
          size="lg"
        >
          <font-awesome-icon :icon="['fas', 'arrow-left']" />
          Back
        </b-button>
      </b-col>
      <b-col md="9">
        <b-button
          variant="secondary"
          @click="onSkip"
          v-if="!hideSkipButton"
          size="lg"
          :disabled="saving"
        >
          Skip
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </b-button>
        <b-button
          variant="primary"
          @click="onSave"
          size="lg"
          v-if="!hideSaveButton"
          :disabled="saving || invalid"
          ><b-spinner
            small
            style="position: relative; top: -3px"
            v-if="saving"
          ></b-spinner>
          {{ saveTextButton }}
          <font-awesome-icon :icon="['fas', 'save']" />
        </b-button>
        <router-link to="dashboard">
          <b-button
            variant="primary"
            size="lg"
            class="ml-3"
            v-if="!hideDashboardButton"
            :disabled="saving"
          >
            <font-awesome-icon :icon="['fas', 'home']" />
            Dashboard
          </b-button>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  emits: ["on-preview", "on-back", "on-skip", "on-save"],
  props: {
    hideBackButton: Boolean,
    hidePreviewButton: Boolean,
    hideSkipButton: Boolean,
    hideSaveButton: Boolean,
    hideDashboardButton: {
      type: Boolean,
      default: true,
    },
    saveTextButton: { type: String, default: "Save & Continue" },
    saving: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onSave(event) {
      this.$emit("on-save");
    },
    onSkip(event) {
      this.$emit("on-skip");
    },
    onPreview(event) {
      this.$emit("on-preview");
    },
    onBack(event) {
      this.$emit("on-back");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-footer-button {
  margin-top: 30px;

  .col-md-9 {
    text-align: right;

    & > .btn {
      margin-left: 15px;
    }

    & > .btn:first-child {
      margin-left: 0;
    }

    @media (max-width: $md-width) {
      & {
        text-align: left;
        margin-top: 30px;
      }
    }
  }
}
</style>
