import axios from "axios";
import { authHeader } from "@/services/authHeader";

const resource = "api/export";

export default {
  ExportUnapprovedEndorsements: userId => {
    axios
      .get(
        `${resource}/unapproved?userId=${userId}`,
        {
          headers: authHeader(),
          responseType: "blob"
        },
        {}
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "unapproved_endorsements.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  },
  ExportSubmittedEndorsements: userId => {
    axios
      .get(
        `${resource}/submitted?userId=${userId}`,
        {
          headers: authHeader(),
          responseType: "blob"
        },
        {}
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "submitted_endorsements.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  },

  ExportEndorsements: (userIds, startDate, endDate) => {
    axios
      .get(
        `${resource}/endorsements?userIds=${userIds}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: authHeader(),
          responseType: "blob"
        },
        {}
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "candidates_endorsements.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  },

  ExportPetitions: userId => {
    axios
      .get(
        `${resource}/petitions?userId=${userId}`,
        {
          headers: authHeader(),
          responseType: "blob"
        },
        {}
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "petitions.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  },

  DownloadNonCandidateStatements: (userId, name) => {
    axios
      .get(
        `${resource}/non-candidate-statements?userId=${userId}&name=${name}`,
        {
          headers: authHeader(),
          responseType: "blob"
        },
        {}
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }
};
