import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
import Candidate from "@/models/Candidate";

export default {
  namespaced: true,
  state() {
    return {
      candidate: new Candidate(),
      allCandidates: [],
      allPositions: [],
    };
  },
  mutations,
  actions,
  getters,
};
