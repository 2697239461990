import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { LoadView } from "@/services/helpers";
import SettingsService from "@/services/SettingsService";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => LoadView(`views/Home`)
  },
  {
    path: "/preview/candidate/:candidate",
    component: () => LoadView(`views/admin/CandidatePreview`)
  },
  {
    path: "/home",
    component: () => LoadView(`views/Home`)
  },
  {
    path: "/template1",
    component: () => LoadView(`views/public/Template1`)
  },
  {
    path: "/template2",
    component: () => LoadView(`views/public/Template2`)
  },
  {
    path: "/template3",
    component: () => LoadView(`views/public/Template3`)
  },
  {
    path: "/template4",
    component: () => LoadView(`views/public/Template4`)
  },
  {
    path: "/login",
    component: () => LoadView(`views/admin/Login`)
  },
  {
    path: "/register",
    component: () => LoadView(`views/admin/Register`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: false }
  },
  {
    path: "/account/reset-password",
    component: () => LoadView(`views/admin/ResetPassword`)
  },
  {
    path: "/admin",
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters["auth/isAuthenticated"];
      if (isAuthenticated) {
        const currentUserId = store.getters["auth/currentUserId"];
        next(`/admin/${currentUserId}/dashboard`);
      } else {
        next("/login");
      }
    }
  },
  {
    path: "/admin/:id/quick-start",
    name: "quick-start",
    component: () => LoadView(`views/admin/QuickStart`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/dashboard",
    name: "dashboard",
    component: () => LoadView(`views/admin/Dashboard`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/theme",
    name: "theme",
    component: () => LoadView(`views/admin/Theme`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/contact-email",
    name: "contact-email",
    component: () => LoadView(`views/admin/ContactEmail`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/social-links",
    name: "social-links",
    component: () => LoadView(`views/admin/ContactSocialLinks`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/name-position",
    name: "name-position",
    component: () => LoadView(`views/admin/AboutNamePosition`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/intro-text",
    name: "intro-text",
    component: () => LoadView(`views/admin/AboutIntroText`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/open-content",
    name: "open-content",
    component: () => LoadView(`views/admin/AboutOpenContent`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/feature-text",
    name: "feature-text",
    component: () => LoadView(`views/admin/AboutFeatureText`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/add-media-block",
    name: "add-media-block",
    component: () => LoadView(`views/admin/AboutMediaBlock`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/manage-media",
    name: "manage-media",
    component: () => LoadView(`views/admin/ManageMediaBlock`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/add-event",
    name: "add-event",
    component: () => LoadView(`views/admin/Event`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/edit-event",
    name: "edit-event",
    component: () => LoadView(`views/admin/EventEdit`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/manage-events",
    name: "manage-events",
    component: () => LoadView(`views/admin/ManageEvents`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/add-endorsement",
    name: "add-endorsement",
    component: () => LoadView(`views/admin/EndorsementsStatement`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/manage-endorsements",
    name: "manage-endorsements",
    component: () => LoadView(`views/admin/ManageEndorsements`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/election-book",
    name: "election-book",
    component: () => LoadView(`views/admin/ElectionBook`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/approved-endorsements",
    name: "approved-endorsements",
    component: () => LoadView(`views/admin/EndorsementsApproved`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/manage-petition",
    name: "manage-petition",
    component: () => LoadView(`views/admin/ManagePetition`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: true }
  },
  {
    path: "/admin/:id/manage-users",
    name: "manage-users",
    component: () => LoadView(`views/admin/ManageUsers`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: false }
  },
  {
    path: "/admin/manage-settings",
    name: "manage-settings",
    component: () => LoadView(`views/admin/ManageSettings`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: false }
  },
  {
    path: "/admin/exclusion-list",
    name: "manage-settings",
    component: () => LoadView(`views/admin/ExclusionList`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: false }
  },
  {
    path: "/admin/candidate-endorsements",
    name: "candidate-report",
    component: () => LoadView(`views/admin/CandidateReport`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: false }
  },
  {
    path: "/admin/manage-homepage",
    name: "manage-homepage",
    component: () => LoadView(`views/admin/ManageHomePage`),
    meta: { requiresAuth: true, adminAuth: true, candidateAuth: false }
  },
  {
    path: `/:year/:candidate`,
    component: () => LoadView(`views/public/Candidate`),
    beforeEnter: async (to, from, next) => {
      const year = to.params.year;
      let electionYear = new Date().getFullYear();
      let settings = store.getters["settings/current"];
      if (!settings.electionYear) {
        const response = await SettingsService.GetSettings();
        if (response.data) {
          electionYear = response.data.electionYear;
        }
      }
      if (parseInt(year) !== electionYear) return false;
      else next();
    }
  },
  {
    path: "*",
    component: () => LoadView(`views/404`)
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const isAuthenticated = store.getters["auth/isAuthenticated"];
    const currentUserRoles = store.getters["auth/currentUserRoles"];
    let hasAccess = false;
    if (to.fullPath.includes("admin")) {
      document.title = "Admin - WGAW Candidate";
    }
    if (!isAuthenticated) {
      next("/login");
    }
    if (to.meta.adminAuth) {
      hasAccess = currentUserRoles.includes("Admin");
    }
    if (!hasAccess && to.meta.candidateAuth) {
      hasAccess = currentUserRoles.includes("Candidate");
    }
    if (hasAccess) next();
    else next("/login");
  } else {
    next();
  }
});

export default router;
