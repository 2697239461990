//import CandidateService2 from "../../../services/CandidateService2";
import * as CandidateService2 from "@/services/CandidateService2";
import CandidateService from "../../../services/candidateService";
import axios from "axios";
import { authHeader } from "@/services/authHeader";

export default {
  async getCandidate(context, userId) {
    const responseData = await CandidateService.get(userId);
    context.commit("SET_CANDIDATE", responseData.data);
    return responseData.data;
  },
  async getPublishedCandidate(context, userId) {
    const responseData = await CandidateService.getPbulished(userId);
    context.commit("SET_CANDIDATE", responseData.data);
    return responseData.data;
  },
  async saveTheme(context, data) {
    const responseData = await CandidateService.saveTheme(
      data.userId,
      data.themeId,
      data.colorId
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveContactEmail(context, data) {
    const responseData = await CandidateService.saveContactEmail(
      data.userId,
      data.email
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveSocialLinks(context, data) {
    const responseData = await CandidateService.saveSocialLinks(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async getPositions(context) {
    const responseData = await CandidateService.getPositions();
    context.commit(`SET_ALL_POSITIONS`, responseData.data);
    context.commit("SET_POSITIONS", responseData.data);
  },
  async saveNamePosition(context, data) {
    const responseData = await CandidateService.saveNamePosition(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveIntroText(context, data) {
    const responseData = await CandidateService.saveIntroText(
      data.userId,
      data.text
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveOpenContent(context, data) {
    const responseData = await CandidateService.saveOpenContent(
      data.userId,
      data.text
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveFeatureText(context, data) {
    const responseData = await CandidateService.saveFeatureText(
      data.userId,
      data.title,
      data.text
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveMedia(context, data) {
    const responseData = await CandidateService.saveMedia(data);
    context.commit("SET_CANDIDATE", responseData);
  },
  async getMedia(context, userId) {
    const responseData = await CandidateService.getMedia(userId);
    // context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveEvent(context, data) {
    const responseData = await CandidateService.saveEvent(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveMediaTable(context, data) {
    const responseData = await CandidateService.saveMediaTableAsync(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async saveEventsTable(context, data) {
    const responseData = await CandidateService.saveEventsTableAsync(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async getAllCandidates({ commit }) {
    let response = await CandidateService.getAllCandidatesAsync();
    commit(`SET_ALL_CANDIDATES`, response.data);
    return response.data;
  },
  async getCandidateByName({ commit }, name) {
    try {
      const response = await axios.get(`api/public/uri/${name}`, {
        headers: authHeader()
      });
      //this.$store.commit("SET_CANDIDATE", response.data);
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
    // CandidateService.getCandidateByNameAsync(name).then(data => {
    //   commit(`SET_CANDIDATE`, data.data);
    // });
  },
  async getCandidatePreviewByName({ commit }, name) {
    try {
      const response = await axios.get(
        `api/candidate/candidate-preview/${name}`,
        {
          headers: authHeader()
        }
      );

      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
    // CandidateService.getCandidateByNameAsync(name).then(data => {
    //   commit(`SET_CANDIDATE`, data.data);
    // });
  },
  async saveEndorsementText(context, data) {
    const responseData = await CandidateService.saveEndorsementText(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  submitEndorsement({ commit }, endorment) {
    return CandidateService.submitEndorsement(endorment);
  },
  async saveEndorsementsTable({ commit }, data) {
    const responseData = await CandidateService.saveEndorsementsTableAsync(
      data
    );
    commit("SET_CANDIDATE", responseData.data);
  },
  async getEndorsementPositions({ dispatch }) {
    try {
      let response = await axios.get(`api/public/positions`, {
        headers: authHeader()
      });
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e.response);
    }
    // return candidateService.getEndorsementPositionsAsync();
  },
  async submitEmail({ dispatch }, data) {
    try {
      let response = await axios.post(`api/public/send-message`, data, {
        headers: authHeader()
      });
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async publishCandidate(context, data) {
    const responseData = await CandidateService.publishCandidate(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async deleteCandidate(context, data) {
    return await CandidateService.deleteCandidate(data);
  },
  async saveFeatureTextAsync({ commit }, data) {
    let formData = new FormData();
    formData.append("userId", data.userId);
    formData.append("title", data.title);
    formData.append("text", data.text);

    if (data.featureImage) formData.append("featureImage", data.featureImage);

    if (data.featureImageUrl)
      formData.append("featureImageUrl", data.featureImageUrl);

    try {
      let response = await axios.post(`api/candidate/feature`, formData, {
        headers: authHeader()
      });
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async submitPetition({ commit }, data) {
    try {
      const response = await axios.post(`api/Public/submit-petition`, data, {
        headers: authHeader()
      });

      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async submitEndorsementsToAdmin({ commit }, userId) {
    const responseData = await CandidateService.submitEndorsementsToAdmin(
      userId
    );
    commit("SET_CANDIDATE", responseData.data);
  },
  async submitPetitionsToAdmin({ commit }, userId) {
    const responseData = await CandidateService.submitPetitionsToAdmin(userId);
    commit("SET_CANDIDATE", responseData.data);
  },
  async updateEndorsementGroup(context, data) {
    const responseData = await CandidateService2.UpdateEndorsementGroupAsync(
      data
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },

  async addNewEndorsementGroup(context, data) {
    const responseData = await CandidateService2.SaveEndorsementGroupAsync(
      data
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },

  async deleteEndorsementGroup(context, data) {
    const responseData = await CandidateService2.DeleteEndorsementGroupAsync(
      data
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },
  async updateLayoutPreference(context, data) {
    const responseData = await CandidateService2.UpdateLayoutPreferenceAsync(
      data
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },

  async saveEndorsementRow(context, data) {
    const responseData = await CandidateService2.SaveEndorsementRowAsync(data);
    context.commit("SET_CANDIDATE", responseData.data);
  },

  async removeFeaturedEndorsementGroupItem(context, data) {
    const responseData = await CandidateService2.RemoveFeaturedEndorsementGroupItem(
      data
    );
    context.commit("SET_CANDIDATE", responseData.data);
  },

  async saveAutoSendCandidateStatements(context, data) {
    const responseData = await CandidateService2.UpdateAutoSendNonCandidateStatements(
      data
    );
    context.commit("SET_AUTO_SEND_CAN_STATEMENTS", data.autoSend);
  }
};
