<template>
  <div class="full-endorsement-list">
    <b-row class="mt-3 mb-4">
      <b-col lg="9">
        <b-dropdown text="Filter by position" class="position-filter">
          <b-dropdown-form>
            <b-form-checkbox-group id="position" v-model="selectedPositions" name="position">
              <b-form-checkbox value="4">Current or former officer or Board of Directors member<span
                  class="key-color key-green"></span></b-form-checkbox>
              <b-form-checkbox value="3">Current or former negotiating committee member<span
                  class="key-color key-yellow"></span></b-form-checkbox>
              <b-form-checkbox value="5">Current or former WGAW PAC Board member<span
                  class="key-color key-purple"></span></b-form-checkbox>
              <b-form-checkbox value="2">Current or former committee chair or vice chair<span
                  class="key-color key-orange"></span></b-form-checkbox>
              <b-form-checkbox value="1">Current or former captain<span
                  class="key-color key-red"></span></b-form-checkbox>
              <b-form-checkbox value="6">No Position</b-form-checkbox>
            </b-form-checkbox-group>
          </b-dropdown-form>
        </b-dropdown>
        <b-button variant="outline-secondary" class="ml-2" @click="onExportAll" :disabled="exporting"><b-spinner small
            style="position: relative; top: -3px" v-if="exporting"></b-spinner>
          Export All
          <font-awesome-icon :icon="['fas', 'file-excel']" />
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
      </b-col>
    </b-row>
    <b-table id="full-endorsements-table" :items="tableData" :fields="fields" :per-page="perPage"
      :current-page="currentPage" :filter="filter" @filtered="onFiltered" :busy="isBusy" responsive>
      <template #head(position)="data">
        <span>{{ data.label }}</span>
        <b-link id="position-legend"><b-icon icon="question-circle-fill" class="position-icon"></b-icon></b-link>
        <b-popover target="position-legend" placement="bottom" triggers="hover">
          <b-row class="flex-column flex-lg-row">
            <b-col class="d-flex">
              <div class="member-key ml-lg-auto">
                <p>
                  <span class="icon"><span class="key-color key-yellow"></span></span>
                  <span class="text">Current or former officer or Board of Directors
                    member</span>
                </p>
                <p>
                  <span class="icon"><span class="key-color key-green"></span></span>
                  <span class="text">Current or former negotiating committee member</span>
                </p>
                <p>
                  <span class="icon"><span class="key-color key-purple"></span></span>
                  <span class="text">Current or former WGAW PAC Board member</span>
                </p>
                <p>
                  <span class="icon"><span class="key-color key-red"></span></span>
                  <span class="text">Current or former committee chair or vice chair</span>
                </p>
                <p>
                  <span class="icon"><span class="key-color key-orange"></span></span>
                  <span class="text">Current or former captain</span>
                </p>
              </div>
            </b-col>
          </b-row>
        </b-popover>
      </template>
      <template #cell(position)="data">
        <span class="key-color key-orange" v-if="colorCheck(1, data.value)"
          v-b-tooltip.hover.bottom="'Current or former captain'"></span>
        <span class="key-color key-red" v-if="colorCheck(2, data.value)" v-b-tooltip.hover.bottom="'Current or former committee chair or vice chair'
          "></span>
        <span class="key-color key-green" v-if="colorCheck(3, data.value)" v-b-tooltip.hover.bottom="'Current or former negotiating committee member'
          "></span>
        <span class="key-color key-yellow" v-if="colorCheck(4, data.value)" v-b-tooltip.hover.bottom="'Current or former officer or Board of Directors member'
          "></span>
        <span class="key-color key-purple" v-if="colorCheck(5, data.value)"
          v-b-tooltip.hover.bottom="'Current or former WGAW PAC Board member'"></span>
      </template>
      <template #cell(display)="data">
        <b-form-checkbox :checked="data.value" @change="toggleDisplay(data)" switch>
        </b-form-checkbox>
      </template>
      <template #cell(featured)="data">
        <b-form-checkbox :checked="data.value" @change="toggleFeatured(data)" switch>
        </b-form-checkbox>
      </template>
      <template #cell(delete)="data">
        <button @click="softDeleteEndorsement(data)" class="btn btn-link">
          <b-icon icon="x" variant="danger"></b-icon>
        </button>
      </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
      aria-controls="full-endorsements-table"></b-pagination>
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
import * as CandidateService from "@/services/CandidateService2";
import { integer } from "vee-validate/dist/rules";
import Repository from "@/repositories/RepositoryFactory";
const ExportRepository = Repository.get("ExportRepository");

export default {
  mixins: [CandidateMixin],
  props: {
    candidate: Object,
    statementId: {
      required: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("settings/getPositions");
  },
  data() {
    return {
      tableData: this.getTableData(this.candidate, this.statementId, []),
      currentPage: 1,
      perPage: 10,
      totalRows: this.candidate.endorsements.length,
      endorsementStatement: null,
      filter: null,
      isBusy: false,
      fields: [
        {
          key: "full_name",
          sortable: false,
        },
        {
          key: "date",
          sortable: true
        },
        {
          key: "position",
          sortable: false,
        },
        {
          key: "email",
          sortable: false,
        },
        {
          key: "display",
          sortable: false,
        },
        {
          key: "featured",
          sortable: false,
        },
        {
          key: "delete",
          sortable: false,
        },
      ],
      selectedPositions: [],
      exporting: false,
      //autoSendNonCandidateStatement: false,
    };
  },
  computed: {
    positions() {
      return this.$store.state.settings.positions.map(({ id, name }) => {
        id, name;
      });
    },
  },
  watch: {
    statementId: function (newVal) {
      var tableData = this.getTableData(
        this.candidate,
        newVal,
        this.selectedPositions
      );
      this.tableData = tableData;
    },
    candidate: function (newVal) {
      var tableData = this.getTableData(
        newVal,
        this.statementId,
        this.selectedPositions
      );
      this.tableData = tableData;
    },
    selectedPositions: function (newVal) {
      var tableData = this.getTableData(
        this.candidate,
        this.statementId,
        newVal
      );
      this.tableData = tableData;
    }
  },
  methods: {


    async onExportAll() {
      try {
        this.exporting = true;
        setTimeout(async () => {
          await ExportRepository.ExportSubmittedEndorsements(
            this.candidateModel.userId
          );
          this.exporting = false;
        }, 500);
      } catch (e) {
        console.log(e);
      }
    },
    getTableData(candidate, statementId, selectedPositions) {
      let tableData = candidate.manageEndorsementList.map((item, index) => {
        const fullName = item.firstName + " " + item.middleName + " " + item.lastName;
        const featured = item.isFeatured;
        const visible = item.isVisible;
        const email = item.email;
        const position = item.position;
        const id = item.endorsementId;
        const endorsementStatementId = item.endorsementStatementId;
        const candidateId = candidate.id;
        const dateSubmitted = item.dateSubmitted;
        return {
          full_name: fullName,
          featured: featured,
          display: visible,
          email: email,
          position: position,
          delete: false,
          id: id,
          endorsementStatementId: endorsementStatementId,
          statementId: statementId,
          candidateId: candidateId,
          date: dateSubmitted
        };
      });

      let filteredData = this.filterByEndorsementStatement(
        tableData,
        statementId
      );
      filteredData = this.filterByPosition(filteredData, selectedPositions);
      this.totalRows = filteredData.length;
      //   filteredData.sort((a, b) => a.full_name.localeCompare(b.full_name));
      return filteredData;
    },
    filterByEndorsementStatement(data, statementId) {
      data = data.filter(function (item) {
        return item.endorsementStatementId == statementId;
      });
      return data;
    },
    filterByPosition(data, positions) {
      var filteredData = [];
      var selected = positions.map(function (item) {
        return parseInt(item);
      });
      if (selected.length > 0) {
        filteredData = data.filter(function (item) {
          var pos = item.position.split(",");
          pos = pos.map((x) => {
            return parseInt(x);
          });
          return pos.some((x) => selected.includes(x));
        });
        if (selected.includes(6)) {
          var noPosition = data.filter(function (item) {
            return item.position.length == 0;
          });
          filteredData = filteredData.concat(noPosition);
        }
        return filteredData;
      }
      return data;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleFeatured(data) {
      data.value = !data.value;
      data.item.featured = data.value;
      this.saveRowData(data);
    },
    toggleDisplay(data) {
      data.value = !data.value;
      data.item.display = data.value;
      this.saveRowData(data);
    },
    softDeleteEndorsement(data) {
      data.item.delete = true;
      this.saveRowData(data);
    },
    saveRowData(data) {
      this.isBusy = true;
      var payload = {
        candidateId: data.item.candidateId,
        endorsementId: data.item.id,
        statementId: data.item.statementId,
        isVisible: data.item.display,
        isFeatured: data.item.featured,
        isDeleted: data.item.delete,
        userId: this.candidate.userId,
      };
      CandidateService.SaveEndorsementRowAsync(payload)
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("candidates/SET_CANDIDATE", res.data);
            this.isBusy = false;
          }, 100);
        })
        .catch((e) => {
          this.isBusy = false;
          this.errorMessage = e;
        });
    },

    colorCheck(id, positions) {
      let pos = positions.split(",");
      pos = pos.map((x) => {
        return parseInt(x);
      });
      var hasPosition = pos.some((x) => x === id);
      return hasPosition;
    },
  },
};
</script>

<style lang="scss">
.full-endorsement-list {
  .position-filter {
    .dropdown-menu {
      width: 475px;
      font-size: 14px;
      font-weight: 400;

      .custom-checkbox {
        display: block;
        margin-right: 0;

        .custom-control-label {
          display: block;
        }

        .key-color {
          float: right;
        }
      }
    }
  }

  #full-endorsements-table {
    .position-icon {
      margin-left: 5px;
      color: #cccccc;
    }
  }

  @media (max-width: 991px) {
    .position-filter {
      margin-bottom: 20px;
    }
  }
}

.popover {
  .member-key p {
    display: table;
    font-weight: 600;
    margin-bottom: 10px;

    .icon {
      display: table-cell;
    }

    .text {
      display: table-cell;
    }
  }

  span.key-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 8px;
  }

  .key-orange {
    background-color: #f2903b;
  }

  .key-red {
    background-color: #e1585b;
  }

  .key-green {
    background-color: #5aa255;
  }

  .key-yellow {
    background-color: #eeca58;
  }

  .key-purple {
    background-color: #b07aa1;
  }
}
</style>
