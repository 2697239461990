<template>
  <div id="app" v-cloak>
    <router-view />
  </div>
</template>

<script>
import SettingsMixin from "@/mixins/settings.mixin";
import Candidate from "@/models/Candidate";
export default {
  mixins: [SettingsMixin],
  data() {
    return {
      isHidden: false,
    };
  },
  mounted() {
    this.checkToken(); //Initial check on page load

    setInterval(() => {
      this.checkToken();
    }, 30 * 60000); //Run token check every 30mins.
  },
  methods: {
    checkToken() {
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        var tokenDT = new Date(user.expiration);
        let currentDT = new Date();

        if (currentDT < tokenDT) {
          let diffTime = Math.abs(tokenDT - currentDT);
          let hours = (diffTime / (1000 * 60 * 60)).toFixed(1);

          if (hours < 1.5) {
            //Revoke token if current hours is 1.5hrs lesser than the token expire date
            this.removeAuth();
          }
        } else {
          this.removeAuth();
        }
      }
    },
    removeAuth() {
      this.$store.commit("candidates/SET_CANDIDATE", new Candidate());
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
#nav {
  font-family: Helvetica, Arial, sans-serif;
}

[v-cloak] {
  display: none;
}

.router-link-exact-active.router-link-active {
  color: #007bff !important;
}
</style>
