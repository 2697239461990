import axios from "axios";
import { authHeader } from "./authHeader";

class AuthService {
  login(user) {
    return axios
      .post("api/auth/login", {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        } else {
          throw response.data.error;
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axios
      .post(
        "api/auth/register",
        {
          email: user.username,
          password: user.password,
          entityId:user.entityId,
          positionType:user.positionType,
          isPetition: user.isPetition,
          name:user.name
        },
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.data.success) {
          return response.data;
        } else {
          throw response.data.message;
        }
      });
  }
}

export default new AuthService();
