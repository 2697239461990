import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";

export default {
  showContinue(state) {
    if (state.candidate) {
      return (
        state.candidate.name !== null ||
        state.candidate.contactEmail !== null ||
        state.candidate.positionId > 0 ||
        state.candidate.profileTheme > 0
      );
    }
    return false;
  },
  showPublish(state) {
    if (state.candidate !== null) {
      return (
        state.candidate.profileTheme > 0 &&
        !!state.candidate.contactEmail &&
        !!state.candidate.name &&
        state.candidate.positionId > 0
      );
    }
    return false;
  },
  nextProgressPath(state) {
    var nextPath = "theme";
    if (state.candidate !== null) {
      if (state.candidate.profileTheme == 0) {
        nextPath = "theme";
      } else if (
        state.candidate.contactEmail == null ||
        state.candidate.contactEmail == ""
      ) {
        nextPath = "contact-email";
      } else if (state.candidate.socialLinks == null) {
        nextPath = "social-links";
      } else if (state.candidate.name == null || state.candidate.name == "") {
        nextPath = "name-position";
      } else if (
        state.candidate.introText == null ||
        state.candidate.introText == ""
      ) {
        nextPath = "intro-text";
      } else if (
        state.candidate.openContent == null ||
        state.candidate.openContent == ""
      ) {
        nextPath = "open-content";
      } else if (
        state.candidate.featureText == null ||
        state.candidate.featureText == ""
      ) {
        nextPath = "feature-text";
      } else if (state.candidate.medias.length == 0) {
        nextPath = "add-media-block";
      } else if (state.candidate.events.length == 0) {
        nextPath = "add-event";
      } else if (
        state.candidate.endorsementStatements.length == 0 &&
        state.candidate.isPetition == false
      ) {
        nextPath = "add-endorsement";
      }
    }

    return nextPath;
  },
  publishedSinceLastSave(state) {
    if (state.candidate != null) {
      return state.candidate.publishedSinceLastSave;
    } else {
      return true;
    }
  },
  candidate: (state) => {
    return state.candidate;
  },
  allCandidates: (state) => {
    return state.allCandidates;
  },
  allPositions: (state) => {
    return state.allPositions;
  },
  showAboutMenuItem(state) {
    if (state.candidate.openContent) {
      return true;
    }
    return false;
  },
  showStatementMenuItem(state) {
    if (state.candidate.featureText) {
      return true;
    }
    return false;
  },
  showEndorsementMenuItem(state) {
    if (
      state.candidate.endorsementStatements[0] ||
      state.candidate.isPetition
    ) {
      return true;
    }
    return false;
  },
  showEventsMenuItem(state) {
    if (state.candidate.events.length !== 0) {
      return true;
    }
    return false;
  },
  hasPendingStatements(state) {
    return state.candidate.endorsementStatements.some(
      (x) => x.isPublished == false
    );
  },
};
