class Settings {
  constructor({
    id = 0,
    electionYear = null,
    lastDateOfEndorsementSubmission = null,
    lastDateOfVoting = null,
  } = {}) {
    this.id = id;
    (this.electionYear = electionYear),
      (this.lastDateOfEndorsementSubmission = lastDateOfEndorsementSubmission),
      (this.lastDateOfVoting = lastDateOfVoting);
  }
}

export default Object.freeze(Settings);
