export default {
  SET_SETTINGS(state, data) {
    state.item = data;
  },
  SET_BOARDMEMBERS(state, data) {
    state.boardMembers = data;
  },
  SET_OFFICERS(state, data) {
    state.officers = data;
  },
  SET_POSITIONS(state, data) {
    state.positions = data;
  },
  SET_INTERNAL_ACCOUNTS(state, data) {
    state.internalAccounts = data;
  },
};
