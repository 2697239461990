<template>
  <b-modal
    v-model="show"
    size="md"
    scrollable
    title="How Can We Help?"
    @hidden="onHide"
    :hide-footer="true"
  >
    <ValidationObserver ref="helpObserver" v-slot="{ invalid }">
      <b-form @submit.prevent="onSubmit">
        <b-alert variant="primary" show>
          We will respond between
          <span class="font-weight-bold"
            >8:00am and 6:00pm (PST) Monday-Friday</span
          >.
        </b-alert>
        <b-alert :show="!!errorMessage" variant="danger">{{
          errorMessage
        }}</b-alert>
        <ValidationProvider
          name="First Name"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group
            label="Your Name"
            label-class="font-weight-bold"
            id="input-group-1"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              type="text"
              placeholder="First Name"
              v-model="form.firstname"
              :state="getValidationState(validationContext)"
              @keyup="errorMessage = false"
              :disabled="userStatusCheck(candidate.name)"
            ></b-form-input>
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="validationContext"
        >
          <b-form-group
            label="Your Name"
            label-class="font-weight-bold"
            id="input-group-2"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              type="email"
              placeholder="E-mail"
              v-model="form.email"
              :state="getValidationState(validationContext)"
              @keyup="errorMessage = false"
              :disabled="userStatusCheck(candidate.contactEmail)"
            ></b-form-input>
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Description"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group
            label="Please describe the issue you are having."
            label-class="font-weight-bold"
          >
            <b-form-textarea
              rows="4"
              max-rows="6"
              v-model="form.description"
              :state="getValidationState(validationContext)"
              @keyup="errorMessage = false"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-button
          type="submit"
          variant="primary"
          class="float-right"
          :disabled="invalid || saving"
          ><b-spinner
            small
            style="position: relative; top: -2px"
            class="mr-2"
            v-if="saving"
          ></b-spinner
          >{{ submitText }}</b-button
        >
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import Candidate from "@/models/Candidate";
import * as CandidateService from "@/services/CandidateService2";

export default {
  name: "info-dialog",
  data: () => ({
    show: false,
    errorMessage: null,
    submitText: "Send",
    saving: false,
    hasDefaultName: false,
    hasDefaultMail: false,
    candidate: new Candidate(),
    isLoggedin: false,
    form: {
      firstname: null,
      email: null,
      description: null,
    },
  }),
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      },
    },
  },
  watch: {
    dialog() {
      this.candidate = new Candidate(this.$store.state.candidates.candidate);
      this.isLoggedin = this.$store.state.auth.loggedIn;
      this.show = this.dialog;

      if (this.candidate.name) this.form.firstname = this.candidate.name;

      if (this.candidate.contactEmail)
        this.form.email = this.candidate.contactEmail;
    },
  },
  methods: {
    getValidationState: ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    },
    userStatusCheck(args) {
      return this.isLoggedin && Boolean(args);
    },
    onSubmit() {
      this.saving = true;
      this.submitText = "Submitting...";
      this.errorMessage = false;

      this.$refs.helpObserver.validate().then((valid) => {
        if (valid) {
          CandidateService.SubmitHelpMailAsync(this.form)
            .then((res) => {
              setTimeout(() => {
                this.resetFields();
                this.submitText = "Sent!";
              }, 500);
              setTimeout(() => {
                this.saving = false;
                this.submitText = "Send";
              }, 1000);
            })
            .catch((e) => (this.errorMessage = e))
            .finally(() => {
              this.submitText = "Send";
              this.saving = false;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
    onHide() {
      this.resetFields();
      this.$emit(`hide`);
    },
    resetFields() {
      if (this.$refs.helpObserver) this.$refs.helpObserver.reset();
      this.form = {
        firstname: this.candidate.name ? this.candidate.name : null,
        email: this.candidate.contactEmail ? this.candidate.contactEmail : null,
        description: null,
      };
    },
  },
};
</script>
