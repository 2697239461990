import XLSX from "xlsx";

let tiktok = "";

export const LoadView = component => {
  if (component)
    return import(/* webpackChunkName: "[request]" */ `@/${component}.vue`);
};

export const GetMediaType = type => {
  let types = {
    1: "Photo",
    2: "Video"
  };

  if (type) return types[type];
};

export const ExtractFilename = file => {
  if (file) return file.split("/").pop();
};

export const ForceEmbedVideo = video => {
  if (!video) return;

  if (video.includes("youtube.com"))
    return video.replace(`/watch?v=`, "/embed/");

  if (video.includes("youtu.be/"))
    return video.replace("youtu.be", "youtube.com/embed");

  if (video.includes("tiktok")) return ExtractTiktokFromPath(video);
  else return video;
};

export const ExtractTiktok = async video => {
  return await fetch(`https://www.tiktok.com/oembed?url=${video}`)
    .then(res => res.json())
    .then(data => data);
};

export const FormatDate = val => {
  if (!val) return;
  let date = new Date(`${val}`);
  date = new Date(date.toString().replace("Standard", "Daylight"));
  return date.toLocaleDateString("en-GB");
};

export const setPosition = positionId => {
  let pos = [
    {
      id: "1",
      value: "President"
    },
    { id: "2", value: "Vice President" },
    { id: "3", value: "Secretary-Treasurer" },
    { id: "4", value: "Board of Directors" }
  ];

  return pos.filter(x => parseInt(x.id) === parseInt(positionId))[0].value;
};

export const setEndorsementPosition = positionId => {
  let pos = [
    {
      text: "current or former Captain",
      value: 1
    },
    {
      text: "current or former Committee Chair, Co-Chair, and/or Vice Chair",
      value: 2
    },
    {
      text: "current or former Negotiating Committee",
      value: 3
    },
    {
      text: "current or former Officer and/or Board of Directors member",
      value: 4
    },
    {
      text: "WGAW PAC Board or working group member",
      value: 5
    }
  ];

  return pos.filter(x => x.value === parseInt(positionId))[0].text;
};

export const nameToUri = name => {
  if (!name) return;

  let uri = name.replace(" ", "-").toLowerCase();
  return uri;
};

export const uriToName = uri => {
  if (!uri) return;
  let name = uri.replace("-", " ").toLowerCase();
  return name;
};

export const ThemeColorLoader = function(colorId) {
  if (!colorId) return;

  const blackTheme = () =>
    import("@/assets/styles/public/theme/color-black.scss");
  const blueTheme = () =>
    import("@/assets/styles/public/theme/color-blue.scss");
  const salmonTheme = () =>
    import("@/assets/styles/public/theme/color-salmon.scss");
  const tealTheme = () =>
    import("@/assets/styles/public/theme/color-teal.scss");
  const grayTheme = () =>
    import("@/assets/styles/public/theme/color-gray.scss");
  const purpleTheme = () =>
    import("@/assets/styles/public/theme/color-purple.scss");

  switch (colorId) {
    case 1:
      blackTheme();
      break;
    case 2:
      blueTheme();
      break;
    case 3:
      salmonTheme();
      break;
    case 4:
      tealTheme();
      break;
    case 5:
      grayTheme();
      break;
    case 6:
      purpleTheme();
      break;
  }
};

export const FormatSocialLink = link => {
  if (!link.includes("https://") && !link.includes("http://")) {
    link = `http://${link}`;
  }

  return link;
};

export const DynamicLoadScript = src => {
  const script = document.createElement("script");
  script.onload = function() {
    //do stuff with the script
    // console.log(`Loading ${src}`);
  };
  script.src = src;
  script.async = true;
  script.type = "text/javascript";

  document.head.appendChild(script);
};

export const ExtractTiktokFromPath = src => {
  if (!src.includes("tiktok")) return;

  try {
    if (src.includes("embed")) return src;
    // if (src.includes("="))
    //   return src.replace(
    //     "https://www.tiktok.com",
    //     "https://www.tiktok.com/player/v1"
    //   );
    if (src.includes("video")) {
      //get last part of url
      let videoId = src.split("/").pop();

      return `https://www.tiktok.com/player/v1/${videoId}`;
    }

    return src;
  } catch (error) {
    console.log(error);
  }
};

export const ExtractYoutubeShortsFromPath = src => {
  if (!src.includes("youtube.com/shorts/")) return;

  try {
    if (src.includes("shorts")) {
      //get last part of url
      let videoId = src.split("/").pop();

      return `https://www.youtube.com/embed/${videoId}`;
    }

    return src;
  } catch (error) {
    console.log(error);
  }
};

export const ExtractVimeoFromPath = src => {
  if (!src.includes("vimeo.com")) return;

  try {
    const videoId = src.split("/").pop();
    return `https://player.vimeo.com/video/${videoId}`;
  } catch (error) {
    console.log(error);
  }
};
